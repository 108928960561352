// Custom button styling

ion-button {
  font-family: 'Albert Sans', 'HelveticaNeue', sans-serif;
  font-weight: 600;
  text-transform: none !important;
  letter-spacing: 0;
  --border-radius: 12px;
  --box-shadow: none;
  height: 44px;
  margin: 4px 0;

  &.button-padded {
    --padding-start: 40px !important;
    --padding-end: 40px !important;
  }
  
  &.button-md-white, .button-ios-white, .button-wp-white {
    color: var(--ion-color-salmon) !important;
  }

  &.button.shadow {
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }

  &.button-margin {
    margin-top: 8px;
  }

  &.button-outline {
    --border-width: 1px;
  }

  &.secondary-invert {
    --background: var(--ion-color-salmon-contrast);
    --background-hover: var(--ion-color-salmon-contrast);
    --color: var(--ion-color-green);
  }

  &.danger-invert {
    --background: var(--ion-color-danger-contrast);
    --background-hover: var(--ion-color-danger-contrast);
    --color: var(--ion-color-danger);
  }

  ion-icon {
    width: 1em !important;
    height: 1em !important;
  }
}

.ios {
  ion-button {
    font-size: 14px !important;
    --border-radius: 4px;
  }
}