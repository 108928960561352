//  Force text-wrap on <ion-select>
ion-select::part(placeholder),
ion-select::part(text) {
  white-space: normal !important;
}
// Force text-wrap on <ion-select-option>'s when shown in an Ionic Alert
ion-alert.select-alert {
  --width: 33vw !important;
  --max-width: 66vw !important;

  .select-interface-option .alert-radio-label,
  .select-interface-option .alert-checkbox-label {
    white-space: normal !important;
  }

  .alert-tappable {
    height: auto !important;
    contain: content !important;
  }
}

ion-select {
  .alert-radio-label {
    -webkit-padding-start: 24px !important;
    padding-inline-start: 24px !important;
    font-size: 13px !important;
  }
  .alert-button {
    font-weight: 600 !important;
  }
  .alert-radio-group.sc-ion-alert-md, .alert-radio-group.sc-ion-alert-md {
    max-height: 360px;
  }
}
