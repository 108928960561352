@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  src: url('./EBGaramond-Regular.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  src: url('./EBGaramond-Medium.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  src:  url('./EBGaramond-SemiBold.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  src:  url('./EBGaramond-Bold.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  src:  url('./EBGaramond-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-style: 700;
  font-weight: italic;
  src:  url('./EBGaramond-BoldItalic.ttf');
}

