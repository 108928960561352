@font-face {
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./AlbertSans-Regular.ttf');
}

@font-face {
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 600;
  src:  url('./AlbertSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Albert Sans';
  font-style: normal;
  font-weight: 700;
  src:  url('./AlbertSans-Bold.ttf');
}
