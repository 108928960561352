// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import "../assets/fonts/AlbertSans/AlbertSans.css";
@import "../assets/fonts/EBGaramond/EBGaramond.css";
@import "../theme/buttons.scss";
@import "../theme/cards.scss";
@import "../theme/alerts.scss";


:root {

  // New color scheme

  // previous ion-color-primary
  --ion-color-green: #6F7563;
  --ion-color-green-rgb: 111, 117, 99;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #3b3e36;
  --ion-color-green-tint: #E9EFEC;

  --ion-color-dark-green: #3b3e36;
  --ion-color-dark-green-rgb: 59, 62, 54;
  --ion-color-dark-green-contrast: #ffffff;
  --ion-color-dark-green-contrast-rgb: 255, 255, 255;
  --ion-color-dark-green-shade: #353831;
  --ion-color-dark-green-tint: #4f514a;

  // previous ion-color-secondary
  --ion-color-salmon: #E6906C;
  --ion-color-salmon-rgb: 230, 144, 108;
  --ion-color-salmon-contrast: #ffffff;
  --ion-color-salmon-contrast-rgb: 255, 255, 255;
  --ion-color-salmon-shade: #91564C;
  --ion-color-salmon-tint: #DEC2AF;

  --ion-color-paper: #F2F0EF;
  --ion-color-paper-rgb: 242, 240, 239;
  --ion-color-paper-contrast: #3b3e36;
  --ion-color-paper-contrast-rgb: 59, 62, 54;
  --ion-color-paper-shade: #dad8d7;
  --ion-color-paper-tint: #ffffff;

  --ion-color-paper-green: #E9EFEC;
  --ion-color-paper-green-rgb: 233, 239, 236;
  --ion-color-paper-green-contrast: #3b3e36;
  --ion-color-paper-green-contrast-rgb: 59, 62, 54;
  --ion-color-paper-green-shade: #d2d7d4;
  --ion-color-paper-green-tint: #f2f5f4;

  --ion-color-red-accent: #DB9C78;
  --ion-color-red-accent-rgb: 219, 156, 120;
  --ion-color-red-accent-contrast: #3b3e36;
  --ion-color-red-accent-contrast-rgb: 59, 62, 54;
  --ion-color-red-accent-shade: #af7d60;
  --ion-color-red-accent-tint: #e2b093;

  --ion-color-super-red-accent: #F5620E;
  --ion-color-super-red-accent-rgb: 245, 98, 14;
  --ion-color-super-red-accent-contrast: #ffffff;
  --ion-color-super-red-accent-contrast-rgb: 255, 255, 255;
  --ion-color-super-red-accent-shade: #c44e0b;
  --ion-color-super-red-accent-tint: #DB9C78;


  // Legacy colors, will be obsolete

  --ion-color-secondary-tint: ;
  --ion-color-secondary-shade: ;

  --ion-color-medium: #E7E1E0;
  --ion-color-medium-rgb: 231,225,224;
  --ion-color-medium-contrast: #3A3E35;
  --ion-color-medium-contrast-rgb: 58,62,53;

  --ion-color-light: #F4F3F3;
  --ion-color-light-rgb: 234,240,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #ced3d7;
  --ion-color-light-tint: #ecf2f5;


  // Generic classes

  --ion-color-white: #ffffff;
  --ion-color-text-light: #8E9093;
  --ion-color-border: rgba(0,0,0,.08);

  --ion-font-family: 'Albert Sans', 'HelveticaNeue', sans-serif;

  // Class registration

  .ion-color-green {
    --ion-color-base: var(--ion-color-green);
    --ion-color-base-rgb: var(--ion-color-green-rgb);
    --ion-color-contrast: var(--ion-color-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-shade);
    --ion-color-tint: var(--ion-color-green-tint);
  }

  .ion-color-dark-green {
    --ion-color-base: var(--ion-color-dark-green);
    --ion-color-base-rgb: var(--ion-color-dark-green-rgb);
    --ion-color-contrast: var(--ion-color-dark-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-dark-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-dark-green-shade);
    --ion-color-tint: var(--ion-color-dark-green-tint);
  }

  .ion-color-salmon {
    --ion-color-base: var(--ion-color-salmon);
    --ion-color-base-rgb: var(--ion-color-salmon-rgb);
    --ion-color-contrast: var(--ion-color-salmon-contrast);
    --ion-color-contrast-rgb: var(--ion-color-salmon-contrast-rgb);
    --ion-color-shade: var(--ion-color-salmon-shade);
    --ion-color-tint: var(--ion-color-salmon-tint);
  }

  .ion-color-paper {
    --ion-color-base: var(--ion-color-paper);
    --ion-color-base-rgb: var(--ion-color-paper-rgb);
    --ion-color-contrast: var(--ion-color-paper-contrast);
    --ion-color-contrast-rgb: var(--ion-color-paper-contrast-rgb);
    --ion-color-shade: var(--ion-color-paper-shade);
    --ion-color-tint: var(--ion-color-paper-tint);
  }

  .ion-color-paper-green {
    --ion-color-base: var(--ion-color-paper-green);
    --ion-color-base-rgb: var(--ion-color-paper-green-rgb);
    --ion-color-contrast: var(--ion-color-paper-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-paper-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-paper-green-shade);
    --ion-color-tint: var(--ion-color-paper-green-tint);
  }

  .ion-color-red-accent {
    --ion-color-base: var(--ion-color-red-accent);
    --ion-color-base-rgb: var(--ion-color-red-accent-rgb);
    --ion-color-contrast: var(--ion-color-red-accent-contrast);
    --ion-color-contrast-rgb: var(--ion-color-red-accent-contrast-rgb);
    --ion-color-shade: var(--ion-color-red-accent-shade);
    --ion-color-tint: var(--ion-color-red-accent-tint);
  }

  .ion-color-super-red-accent {
    --ion-color-base: var(--ion-color-super-red-accent);
    --ion-color-base-rgb: var(--ion-color-super-red-accent-rgb);
    --ion-color-contrast: var(--ion-color-super-red-accent-contrast);
    --ion-color-contrast-rgb: var(--ion-color-super-red-accent-contrast-rgb);
    --ion-color-shade: var(--ion-color-super-red-accent-shade);
    --ion-color-tint: var(--ion-color-super-red-accent-tint);
  }

}
