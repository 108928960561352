// Custom card styling

ion-card {
  --ion-item-background: var(--ion-color-medium);
  margin: 0px 0px 12px 0px !important;
  width: 100%;
  border-radius: 16px !important;
  box-shadow: none !important;

  ion-card-title {
    display: block;
    font-size: 2.4rem;
    line-height: 1.2;
    color: #222;
    margin: 2px 0;
    padding: 8px 0;
  }

  ion-card-header {
    display: inline-block;
    width: 100%;
    padding: 12px 16px;
    margin-bottom: 16px;
    h3 {
      font-size: 14px;
      margin: 2px 0;
      color: #000;
    }
  }

  ion-card-content.padding-top {
    padding-top: 16px !important;
  }

  p {
    color: var(--ion-color-dark-green);
  }

  .list {
    .item {
      border-bottom: 1px solid #c8c7cc;
      padding-left: 0px;
      background-color: transparent;
    }

    .item-block:first-child {
      border-top: none;
    }
  }

  &.header-colored {
    ion-card-header {
      --background: var(--ion-color-green);
      h3 {
        color: var(--ion-color-green-contrast) !important;
        font-size: 1em;
        margin: 2px 0;
      }
    }
  }

  &.bordered {
    border: 1px solid var(--ion-color-green);
    ion-card-header {
      border-bottom: 1px solid var(--ion-color-border);
    }
  }
}
